import axios from "axios";
import config from "./config";
import { LocalStorage } from "./LocalStorage";
import SupportAccountServices from "./supportAccount";

const axiosInstance = axios.create({
  baseURL: config.getBaseUrl(),
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const user = SupportAccountServices.getUserID();

    config.headers = {
      Authorization: `Bearer ${LocalStorage.getToken()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    // console.log("USER ID", user);
    if (user !== null) {
      config.params = { ...config.params, userId: user };
    }
    return config;
  },
  (error) => {
    throw new Error(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("Error1: ", error.response, error);
    if (error.response?.status === 401) LocalStorage.removeToken();
    if (error.message === "canceled") return Promise.reject(error);
    return Promise.reject(error.response);
  }
);
const httpServices = {
  getMethod(url: string, params?: any, signal?: AbortSignal) {
    // console.log("Signal", signal);
    return axiosInstance.get(url, {
      params: params,
      signal: signal,
    });
  },
  getDoc(url: string, params?: any, signal?: AbortSignal) {
    return axiosInstance.get(url, {
      params: params,
      responseType: "blob",
    });
  },
  postMethod(url: string, params?: any, signal?: AbortSignal) {
    return axiosInstance.post(url, params, {
      signal: signal,
    });
  },
  putMethod(url: string, params?: any, signal?: AbortSignal) {
    return axiosInstance.put(url, params);
  },
  deleteMethod(url: string, params?: any, signal?: AbortSignal) {
    return axiosInstance.delete(url, params);
  },
};

export { httpServices };
