import { httpServices } from "services/http";
import SupportAccountServices from "./supportAccount";

const GlobalProfileServices = {
  timer: null as any,
  profile: null as any,
  supportProfile: null as any,
  getProfileDetails: function (params = null) {
    return httpServices.getMethod("user/details", params).then((res: any) => {
      let groupBy: any = {};
      res.data.tableColumns.map((item: any) => {
        if (groupBy[item.tableEnum]) {
          groupBy[item.tableEnum].push(item);
        } else {
          groupBy[item.tableEnum] = [];
          groupBy[item.tableEnum].push(item);
        }
      });
      for (const item in groupBy) {
        // console.log("Loopeeeeed: ", item);
        groupBy[item] = groupBy[item].sort(
          (a: any, b: any) => a.sortingOrder - b.sortingOrder
        );
      }
      res.data.tableColumns = groupBy;
      // .sort(
      //   (a: any, b: any) => a.sortingOrder - b.sortingOrder
      // );
      res.data.language = res?.data?.language?.toLowerCase() || "en";
      if (res.data.config) {
        res.data.config = res.data.config.replaceAll("'", '"');

        res.data.config = JSON.parse(res.data.config);
      } else {
        res.data["config"] = null;
      }
      localStorage.setItem("profile", JSON.stringify(res.data));
      return res.data;
    });
  },
  getProfileData: async function (force = false) {
    console.log(
      "PROFILE",
      this.profile === null,
      localStorage.getItem("profile") === null,
      force,
      "----",
      (this.profile === null && localStorage.getItem("profile") === null) ||
        force === true
    );
    // clearTimeout(this.timer);
    // this.timer = setTimeout(async () => {
    if (
      (this.profile === null && localStorage.getItem("profile") === null) ||
      force === true
    ) {
      this.profile = await this.getProfileDetails();
      //   console.log("Profile Called API:", this.profile);
      return this.profile;
    } else if (
      this.profile === null &&
      localStorage.getItem("profile") !== null
    ) {
      this.profile = JSON.parse(localStorage.getItem("profile") || "");
      //   console.log("Profile in LocalStorage:", this.profile);
      return this.profile;
    } else {
      console.log("Profile in Cache:", this.profile);
      return this.profile;
    }
    // }, 20);
  },
  hasSupportAccountRole: function (force = false) {
    return this.profile?.roles?.some((role: any) => {
      if (role.id === 12 || role.name === "SUPPORTACCOUNT") return true;
    });
  },
  updateTutorial: function (params: any) {
    return httpServices.putMethod("/user/update/config", params);
  },
  getSupportProfileDetails: function () {
    let url = "user/details";
    let userID = localStorage.getItem("userID");
    if (SupportAccountServices.getUserID()) {
      url = "support-account/user/" + userID + "/details";
    }
    return httpServices.getMethod(url, null).then((res: any) => {
      console.log("DATA", res);
      let groupBy: any = {};
      res.data.result.tableColumns.map((item: any) => {
        if (groupBy[item.tableEnum]) {
          groupBy[item.tableEnum].push(item);
        } else {
          groupBy[item.tableEnum] = [];
          groupBy[item.tableEnum].push(item);
        }
      });
      for (const item in groupBy) {
        groupBy[item] = groupBy[item].sort(
          (a: any, b: any) => a.sortingOrder - b.sortingOrder
        );
      }
      res.data.result.tableColumns = groupBy;
      // .sort(
      //   (a: any, b: any) => a.sortingOrder - b.sortingOrder
      // );
      if (res.data.result.config) {
        res.data.result.config = res.data.result.config.replaceAll("'", '"');

        res.data.result.config = JSON.parse(res.data.result.config);
      } else {
        res.data.result["config"] = null;
      }

      return res.data.result;
    });
  },
  getSupportProfileData: async function (force = false) {
    // clearTimeout(this.timer);
    // this.timer = setTimeout(async () => {
    if (this.supportProfile === null || force === true) {
      this.supportProfile = await this.getSupportProfileDetails();
      console.log("Support Profile Called API:", this.supportProfile);
      return this.supportProfile;
    } else {
      console.log("Support Profile in Cache:", this.supportProfile);
      return this.supportProfile;
    }
    // }, 20);
  },
};

export default GlobalProfileServices;
